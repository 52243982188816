import { Button, Modal } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import colors from '../styles/colors';
import { IVerifyEmailData } from '../types/verify-email/data.model';

interface Props {
  className?: string;
  data: IVerifyEmailData;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const ModalEmailAlreadyExists: React.FC<Props> = ({ className, data, visible, setVisible }) => {
  const { t } = useTranslation();

  const onCancel = () => {
    setVisible(false);
  };

  const access = data.access;

  const convertTrueValuesToString = (access: any) => {
    const strings = [];
    for (const key in access) {
      if (access.hasOwnProperty(key) && access[key]) {
        switch (key) {
          case 'admin':
            strings.push(t('MODAL_EMAIL_ALREADY_EXISTS.ADMINISTRATOR'));
            break;
          case 'hr':
            strings.push(t('MODAL_EMAIL_ALREADY_EXISTS.HR'));
            break;
          case 'planning':
            strings.push(t('MODAL_EMAIL_ALREADY_EXISTS.PLANNING'));
            break;
          case 'user':
            strings.push(t('MODAL_EMAIL_ALREADY_EXISTS.USER'));
            break;
        }
      }
    }

    const lastIndex = strings.length - 1;
    if (lastIndex >= 0 && strings.length > 1) {
      strings[lastIndex] = `${t('AND')} ${strings[lastIndex]}`;
    }

    let result = strings.join(', ');
    if (lastIndex >= 1) {
      result = result.replace(/,([^,]*)$/, ' $1');
    }

    return result;
  };

  const onLogin = () => {
    if (!access) return;

    if (access.admin || access.hr || access.planning) {
      window.open(process.env.REACT_APP_SHYFTER_APP_URL, '_blank');
    } else {
      window.open(process.env.REACT_APP_SHYFTER_MOBILE_URL, '_blank');
    }
  };

  const onCreateNewAccount = () => {
    setVisible(false);
  };

  return (
    <Modal
      forceRender={true}
      destroyOnClose={true}
      visible={visible}
      onCancel={onCancel}
      footer={null}
      className={className}
      maskClosable
    >
      <div className="container">
        <div className="header">
          <h2 className="title">{t('MODAL_EMAIL_ALREADY_EXISTS.HEADER.TITLE')}</h2>
          <p className="subtitle">
            {t('MODAL_EMAIL_ALREADY_EXISTS.HEADER.SUBTITLE', {
              types: convertTrueValuesToString(access),
              count: data.count,
            })}
          </p>
        </div>
        <div className="content">
          <h3 className="title">{t('MODAL_EMAIL_ALREADY_EXISTS.CONTENT.TITLE')}</h3>
          <Button className="login-btn" size="large" type="primary" onClick={onLogin}>
            {t('MODAL_EMAIL_ALREADY_EXISTS.CONTENT.LOGIN')}
          </Button>
          <span>{t('OR')}</span>
          <Button className="create-account-btn" size="large" type="primary" onClick={onCreateNewAccount}>
            {t('MODAL_EMAIL_ALREADY_EXISTS.CONTENT.CREATE_NEW_ACCOUNT')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default styled(ModalEmailAlreadyExists)`
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .header {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      .title {
        color: ${colors.red};
        font-weight: bold;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 5px;
      margin-top: 10px;

      .title {
        font-weight: bold;
      }

      .login-btn {
        background-color: ${colors.purple};
        border-color: ${colors.purple};
      }

      .create-account-btn {
      }
    }
  }
`;
