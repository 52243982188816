import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import WorkerChoice from './WorkerChoice';
import { useLocation } from 'react-router-dom';

interface Props {
  className?: string;
  onNext: () => void;
  onNextPerson: () => void;
}

const Step1: React.FC<Props> = ({ className, onNext, onNextPerson }) => {
  const { t } = useTranslation(undefined, { useSuspense: true });
  const search = useLocation().search;
  const params = new URLSearchParams(search);
  const type = params.get('type');

  return (
    <div className={className}>
      <h1>{t('STEP_1.TITLE')}</h1>
      <p>{t('STEP_1.DESCRIPTION')}</p>
      {params.get('type') && params.get('type') == 'clocking' ? null : <h2>{t('STEP_1.SUBTITLE')}</h2>}
      <div className="choices">
        <WorkerChoice name={t('STEP_1.WORKER_CHOICES.HR_MANAGER')} onNext={onNext} />
        <WorkerChoice name={t('STEP_1.WORKER_CHOICES.STORE_MANAGER')} onNext={onNext} />
        <WorkerChoice name={t('STEP_1.WORKER_CHOICES.STUDENT')} onNext={onNextPerson} />
        <WorkerChoice name={t('STEP_1.WORKER_CHOICES.PLANNING_MANAGER')} onNext={onNext} />
        <WorkerChoice name={t('STEP_1.WORKER_CHOICES.TEAM_MANAGER')} onNext={onNext} />
        <WorkerChoice name={t('STEP_1.WORKER_CHOICES.SHYFTER_USER')} onNext={onNextPerson} />
        <WorkerChoice name={t('STEP_1.WORKER_CHOICES.BUSINESS_LEADER')} onNext={onNext} />
        {/* <WorkerChoice name={t('STEP_1.WORKER_CHOICES.OTHER')} onNext={onNext} /> */}
      </div>
      <p className="add-on">
        {t('STEP_1.ADD_ON')}
        <br />
        <a style={{ fontSize: 16 }} href="https://v3-app.shyfter.co" rel="noreferrer" target="_blank">
          {process.env.REACT_APP_SHYFTER_APP_URL}
        </a>
      </p>
    </div>
  );
};

export default styled(Step1)`
  .choices {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .choice {
      flex: 1;
      .choice-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 224px;
        cursor: pointer;
        position: relative;

        img {
          max-width: 100%;
        }
        h3 {
          font-style: normal;
          font-weight: bold;
          font-size: 22px;
          line-height: 26px;
          margin: 25px 0 10px;
          text-align: center;
        }
        p {
          text-align: center;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }

  .add-on {
    margin-top: 50px;
  }
`;
