import { Button } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props {
  className?: string;
  onPrevious?: () => void;
}

const StepEmployee: React.FC<Props> = ({ className, onPrevious }) => {
  const { t } = useTranslation(undefined, { useSuspense: true });

  const onClick = () => {
    window.open('https://shyfter.co', '_blank');
  };

  return (
    <div className={className}>
      <Button onClick={onPrevious} size="small" type="link" style={{ marginBottom: 25 }}>
        &lt; {t('BACK')}
      </Button>
      <h1>{t('STEP_EMPLOYEE.TITLE')}</h1>
      <p>{t('STEP_EMPLOYEE.DESCRIPTION')}</p>
      <Button type="primary" block onClick={onClick}>
        {t('STEP_EMPLOYEE.BUTTON')}
      </Button>
      {/* <p style={{ fontWeight: 'bold', color: '#7F8894' }}>
        {t('STEP_EMPLOYEE.DESCRIPTION_BOLD')}{' '}
        <a style={{ fontSize: 16 }} href="https://v3-app.shyfter.co" rel="noreferrer" target="_blank">
          {process.env.REACT_APP_SHYFTER_APP_URL}
        </a>
      </p> */}
      {/* <div className="stores">
        <a href={process.env.REACT_APP_GOOGLE_PLAY_URL} rel="noreferrer" target="_blank">
          <img className="logo" src={process.env.PUBLIC_URL + '/img/google-play.svg'} alt="google-play" />
        </a>
        <a href={process.env.REACT_APP_APP_STORE_URL} rel="noreferrer" target="_blank">
          <img className="logo" src={process.env.PUBLIC_URL + '/img/app-store.svg'} alt="app-store" />
        </a>
      </div> */}
    </div>
  );
};

export default styled(StepEmployee)`
  position: relative;

  .logo {
    width: 200px;
  }

  .stores {
    display: flex;
  }
`;
