import { Button, Modal } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import colors from '../styles/colors';
import { IVerifyEmailData } from '../types/verify-email/data.model';

interface Props {
  className?: string;
  data: IVerifyEmailData;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const ModalAccountOnHold: React.FC<Props> = ({ className, data, visible, setVisible }) => {
  const { t } = useTranslation();

  const onCancel = () => {
    setVisible(false);
  };

  const onBack = () => {
    setVisible(false);
    window.open(process.env.REACT_APP_SHYFTER_WEBSITE_URL, '_blank');
  };

  return (
    <Modal destroyOnClose={true} visible={visible} onCancel={onCancel} footer={null} className={className} maskClosable>
      <div className="container">
        <div className="header">
          <h2 className="title">{t('MODAL_ACCOUNT_ON_HOLD.TITLE')}</h2>
          <p className="subtitle">{t('MODAL_ACCOUNT_ON_HOLD.SUBTITLE')}</p>
        </div>
        <Button type="primary" className="back-btn" size="large" onClick={onBack}>
          {t('MODAL_ACCOUNT_ON_HOLD.BACK_ON_SITE')}
        </Button>
      </div>
    </Modal>
  );
};

export default styled(ModalAccountOnHold)`
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .header {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      .title {
        color: ${colors.red};
        font-weight: bold;
      }

      .subtitle {
        padding: 0 75px;
      }
    }

    .back-btn {
      margin-top: 15px;
      background-color: ${colors.purple};
      border-color: ${colors.purple};
    }
  }
`;
