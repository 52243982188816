import { ConfigProvider, Spin } from 'antd';
import enUS from 'antd/es/locale/en_US';
import esEs from 'antd/es/locale/es_ES';
import frFR from 'antd/es/locale/fr_FR';
import nlNL from 'antd/es/locale/nl_NL';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import TagManager from 'react-gtm-module';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import App from './App';
import './i18n';
import i18n from './i18n';
import './index.css';

const tagManagerArgs = {
  gtmId: 'GTM-MWHZB62',
  dataLayerName: 'PageDataLayer',
};

TagManager.initialize(tagManagerArgs);

type TLanguages = 'fr' | 'en' | 'nl' | 'es';

const languages = {
  fr: frFR,
  en: enUS,
  nl: nlNL,
  es: esEs,
};

const Root: React.FC = () => {
  const {
    i18n: { language },
  } = useTranslation(undefined, { useSuspense: true });

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY!}
      language={language}
      useRecaptchaNet={true}
      useEnterprise={true}
      scriptProps={{
        async: false, // optional, default to false,
        defer: false, // optional, default to false
        appendTo: 'head', // optional, default to "head", can be "head" or "body",
        nonce: undefined, // optional, default undefined
      }}
    >
      <I18nextProvider i18n={i18n}>
        <BrowserRouter>
          <ConfigProvider locale={languages[language as TLanguages]}>
            <Switch>
              <Route exact path={`/`} render={() => <Redirect to={`/fr`} />} />
              <Route path="/:lang(fr|en|nl|es)">
                <App />
              </Route>
              <Redirect to={`/fr`} />
            </Switch>
          </ConfigProvider>
        </BrowserRouter>
      </I18nextProvider>
    </GoogleReCaptchaProvider>
  );
};

ReactDOM.render(
  //<React.StrictMode>
  <Suspense fallback={<Spin spinning={true} style={{ width: '100%', height: '100vh' }} />}>
    <Root />
  </Suspense>,
  //</React.StrictMode>,
  document.getElementById('root'),
);
