import React from 'react';
import styled from 'styled-components';

interface Props {
  className?: string;
  name: string;
  onNext: () => void;
}

const WorkerChoice: React.FC<Props> = ({ className, name, onNext }) => {
  const onClick = () => {
    // SEND DATA
    onNext();
  };

  return (
    <div className={className} onClick={onClick}>
      {name}
    </div>
  );
};

export default styled(WorkerChoice)`
  cursor: pointer;
  padding: 12px 40px;
  border: 1px solid #c4c4c4;
  border-radius: 10px;
  margin: 20px 25px 0 0;
  max-width: 250px;
`;
