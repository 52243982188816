import React, { useCallback, useEffect, useState } from 'react';
import { Button, Checkbox, Form, FormInstance, Input, Select } from 'antd';
import PlaceAutocomplete from './PlaceAutocomplete';
import { IAddress } from '../types/address.model';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import colors from '../styles/colors';
import { useLocation } from 'react-router-dom';

const { Option } = Select;

interface Props {
  className?: string;
  onPrevious: () => void;
  isSaving: boolean;
  form: FormInstance;
}

const Step3: React.FC<Props> = ({ className, onPrevious, isSaving, form }) => {
  const { t } = useTranslation(undefined, { useSuspense: true });
  const onAddressChange = (address: IAddress) => {
    form.setFieldsValue({
      ...address,
      ['address']: address.fullAddress,
    });
  };
  const searchParams = new URLSearchParams(document.location.search);
  const specs = searchParams.get('specs');
  const hasSpecs = specs && specs == 'newaccount';
  const search = useLocation().search;
  const params = new URLSearchParams(search);
  const type = params.get('type');

  const needsChoices: any[] = [
    {
      label: t(`STEP_3.SCHEDULE_ONLY${hasSpecs ? '_SPECS' : ''}`),
      value: 'schedule',
    },
    {
      label: t(`STEP_3.SCHEDULE_CLOCKING${hasSpecs ? '_SPECS' : ''}`),
      value: 'clocking+schedule',
    },
  ];

  if (!hasSpecs) {
    needsChoices.unshift({
      label: t('STEP_3.TIME_RECORD'),
      colored: t('STEP_3.MAX_FREE_USERS'),
      value: 'clocking',
    });
  }

  return (
    <div className={className}>
      <Button onClick={onPrevious} loading={isSaving} size="small" type="link" style={{ marginBottom: 25 }}>
        &lt; {t('BACK')}
      </Button>
      <h1>{t('STEP_3.TITLE')}</h1>
      {params.get('type') && params.get('type') == 'clocking' ? null : <h2>{t('STEP_3.SUBTITLE')}</h2>}
      <div className="fields">
        <Form.Item
          label={t('STEP_3.COMPANY_NAME')}
          name="company"
          rules={[{ required: true, message: t('STEP_3.COMPANY_NAME_ERROR') }]}
        >
          <Input size="large" placeholder={t('STEP_3.COMPANY_NAME')} />
        </Form.Item>
        <Form.Item
          label={t('STEP_3.FULL_ADDRESS')}
          name="address"
          rules={[
            {
              required: true,
              message: t('STEP_3.FULL_ADDRESS_ERROR'),
              validator: (rule, value) => {
                if (value && form.getFieldValue('countryCode')) {
                  return Promise.resolve(value);
                } else {
                  return Promise.reject('error');
                }
              },
            },
          ]}
        >
          <PlaceAutocomplete onAddressChange={onAddressChange} />
        </Form.Item>
        <Form.Item style={{ display: 'none' }} label="Ville" name="city">
          <Input disabled={true} />
        </Form.Item>
        <Form.Item style={{ display: 'none' }} label="Code postal" name="zip">
          <Input disabled={true} />
        </Form.Item>
        <Form.Item style={{ display: 'none' }} label="Pays" name="country">
          <Input disabled={true} />
        </Form.Item>
        <Form.Item style={{ display: 'none' }} label="Code pays" name="countryCode">
          <Input disabled={true} />
        </Form.Item>
        <Form.Item style={{ display: 'none' }} label="Longitude" name="lng">
          <Input disabled={true} />
        </Form.Item>
        <Form.Item style={{ display: 'none' }} label="Latitude" name="lat">
          <Input disabled={true} />
        </Form.Item>
        <Form.Item label={t('STEP_3.VAT_NUMBER')} name="vat">
          <Input size="large" placeholder={t('STEP_3.VAT_NUMBER')} />
        </Form.Item>
        {params.get('type') && params.get('type') == 'clocking' ? null : (
          <Form.Item
            name="need"
            label={t('STEP_3.MY_NEEDS')}
            rules={[{ required: true, message: t('STEP_3.YOUR_NEEDS_ERROR') }]}
          >
            <Select placeholder={t('STEP_3.SELECT_ACCOUNT_TYPE')}>
              {needsChoices.map((choice) => (
                <Select.Option key={choice.value} value={choice.value}>
                  {choice.colored ? (
                    <>
                      <span>{choice.label}</span>
                      <span style={{ color: colors.green, fontWeight: 'bold' }}> {choice.colored}</span>
                    </>
                  ) : (
                    choice.label
                  )}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
        <Form.Item
          name="people"
          label={t('STEP_3.NUMBER_OF_PEOPLE')}
          rules={[{ required: true, message: t('STEP_3.NUMBER_OF_PEOPLE_ERROR') }]}
        >
          <Select getPopupContainer={(trigger) => trigger} placeholder={t('STEP_3.NUMBER_OF_PEOPLE')}>
            <Option value="1-10">1-10</Option>
            <Option value="11-20">11-20</Option>
            <Option value="21-50">21-50</Option>
            <Option value="51-100">51-100</Option>
            <Option value="+100">+100</Option>
          </Select>
        </Form.Item>
        <Form.Item
          label={t('STEP_3.ACTIVITY_AREA')}
          name={['activity_area']}
          rules={[{ required: true, message: t('STEP_3.ACTIVITY_AREA_ERROR') }]}
        >
          <Select getPopupContainer={(trigger) => trigger} placeholder={t('STEP_3.ACTIVITY_AREA_PLACEHOLDER')}>
            <Option value="horeca">{t('STEP_3.ACTIVITY_AREA_VALUES.HORECA')}</Option>
            <Option value="food_retail">{t('STEP_3.ACTIVITY_AREA_VALUES.DETAIL')}</Option>
            <Option value="events">{t('STEP_3.ACTIVITY_AREA_VALUES.EVENTS')}</Option>
            <Option value="sports_leisure">{t('STEP_3.ACTIVITY_AREA_VALUES.SPORTS')}</Option>
            <Option value="hotels">{t('STEP_3.ACTIVITY_AREA_VALUES.HOTEL')}</Option>
            <Option value="transport">{t('STEP_3.ACTIVITY_AREA_VALUES.TRANSPORT')}</Option>
            <Option value="health">{t('STEP_3.ACTIVITY_AREA_VALUES.BEAUTY')}</Option>
            <Option value="other">{t('STEP_3.ACTIVITY_AREA_VALUES.OTHER')}</Option>
          </Select>
        </Form.Item>
      </div>
      <Form.Item>
        <Button
          id="lead"
          className="lead"
          loading={isSaving}
          size="large"
          type="primary"
          htmlType="submit"
          style={{ width: '100%', marginBottom: 0 }}
        >
          {t('STEP_3.BUTTON')}
        </Button>
      </Form.Item>
    </div>
  );
};

export default styled(Step3)`
  padding-bottom: 50px;
  position: relative;
  flex: 1;

  .back-button {
    display: flex;
  }

  .ant-radio-group {
    label.ant-radio-wrapper {
      span:last-child {
        font-size: 16px;
        font-weight: normal;
        color: grey;
      }
    }
  }

  .needs-choices {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    gap: 15px;

    span {
      font-size: 14px;
    }

    .ant-checkbox-wrapper {
      margin-left: 0;
    }
  }
`;
