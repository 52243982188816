export default {
  green: '#00A651',
  greenLight: '#CCEDDC',
  greenExtraLight: '#F3FEF8',
  blue: '#273140',
  blueLight: '#E2E6EF',
  blueExtraLight: '#F6F9FF',
  red: '#ED6E5D',
  redLight: '#FFCFC8',
  redDark: '#f44336',
  redExtraLight: '#FCF3F1',
  grey: '#7f8894',
  greyLight: '#efefef',
  orange: '#FF9D0B',
  bluePastel: '#6B7897',
  blueLightPastel: '#60CDFF',
  purple: '#9799FF',
};

export const colorPalette: string[] = [
  '#f44336',
  '#e91e63',
  '#9c27b0',
  '#673ab7',
  '#3f51b5',
  '#2196f3',
  '#03a9f4',
  '#00bcd4',
  '#009688',
  '#4caf50',
  '#8bc34a',
  '#cddc39',
  '#ffeb3b',
  '#ffc107',
  '#ff9800',
  '#ff5722',
  '#795548',
  '#607d8b',
];
