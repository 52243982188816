import { Button } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props {
  className?: string;
}

const NewLead: React.FC<Props> = ({ className }) => {
  const { t } = useTranslation(undefined, { useSuspense: true });

  return (
    <div className={className}>
      <h1>{t('NEW_LEAD.TITLE')}</h1>
      <h2>{t('NEW_LEAD.SUBTITLE')}</h2>
      <p>{t('NEW_LEAD.CONTENT')}</p>
      <p>{t('NEW_LEAD.OUTRO')}</p>
      <a target="_blank" rel="noreferrer" href="https://shyfter.co">
        shyfter.co
      </a>
    </div>
  );
};

export default styled(NewLead)``;
